import "./textbox.css";

const TextBox = ({
  inputType,
  error,
  onChange,
  value,
  placeholder,
  isDisabled,
  width,
  height,
}) => {
  return (
    <div className="textbox-container">
      <label className="input-label">{inputType}</label>
      <textarea
        style={{ width: width, height: height }}
        className={`textbox ${error ? "error" : ""}`}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={isDisabled}
      ></textarea>
      {error && <p className="errortext">{error}</p>}
    </div>
  );
};
export default TextBox;
