import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input } from "../input";
import "./secondaryheader.css";
import { useState } from "react";
import { Button } from "../button";

const searchBoxProps = {
  members: {
    placeholder: "Search Member",
  },
  provider: {
    placeholder: "Search Provider",
  },
};
const SecondaryHeader = ({
  currentPage,
  member,
  accessType,
  onSearch,
  onAdvanceSearch,
}) => {
  const params = useParams();
  const location = useLocation();
  const state = accessType
    ? JSON.stringify({ member: member, accessType: accessType })
    : location.state;
  const navigate = useNavigate();
  const id = member?.id || params?.id;
  const pageTabs = {
    "member-detail": [
      {
        name: "Details",
        path: `/member/${id}/detail`,
        state: state,
      },
      {
        name: "Authorization",
        path: `/member/${id}/auth`,
        state: state,
      },
    ],
  };
  const [searchValue, setSearchValue] = useState("");
  const onChange = (e) => {
    setSearchValue(e.target.value);
    onSearch(e);
  };
  return (
    <div className="secondary-header">
      <div className="secondary-header-left-container">
        {pageTabs[currentPage]?.map((tab) => (
          <div
            className={`secondary-header-item ${
              location.pathname === tab.path ||
              ((location.pathname === "/" || location.pathname === "/member") &&
                tab.name === "Details") ||
              (location.pathname.includes("/auth") &&
                tab.name === "Authorization")
                ? "selected"
                : ""
            }`}
            key={tab.name}
            onClick={() => navigate(tab.path, { state: tab.state })}
          >
            {tab.name}
          </div>
        ))}
      </div>
      {(currentPage === "provider" || currentPage === "members") && (
        <div className="secondary-header-right-container">
          <div className="searchbox">
            <Input
              placeholder={searchBoxProps[currentPage].placeholder}
              onChange={onChange}
              value={searchValue}
            />
          </div>
          {currentPage === "provider" && (
            <Button
              onClick={onAdvanceSearch}
              buttonType={"primary"}
              width={"130px"}
            >
              Advanced Search
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
export default SecondaryHeader;
