import { useState } from "react";
import { Input, TextBox } from "../../components";
import moment from "moment/moment";
import AddBenificiary from "./addbenificiary";
import { Checkbox } from "../../components/checkbox";
import { Button } from "../../components/button";

const allergiesOptions = ["Sinusitis", "Asthma", "Anaphylaxis", "Rhinitis"];
const MemberForm = ({ type, selectedMember = {}, onSubmit, onCancel }) => {
  const accessType = sessionStorage.getItem("accessType");
  const [firstName, setFirstName] = useState(selectedMember.firstName || "");
  const [lastName, setLastName] = useState(selectedMember.lastName || "");
  const [dob, setDob] = useState(
    selectedMember.dob
      ? moment(new Date(selectedMember.dob)).format("YYYY-MM-DD")
      : ""
  );
  const [address, setAddress] = useState(selectedMember.address || "");
  const [addressError, setAddressError] = useState("");
  const [contact, setContact] = useState(selectedMember.contact || "");
  const [diagnosis, setDiagnosis] = useState(selectedMember.diagnosis || "");
  const [selectedAllergies, setSelectedAllergies] = useState(
    selectedMember.allergies?.split(", ") || []
  );
  const [benificiaries, setBenificiaries] = useState(
    selectedMember.benificiary || []
  );
  const [isError, setIsError] = useState(false);
  const [contactError, setContactError] = useState("");
  const onChange = (e, type) => {
    if (type === "firstName") {
      setFirstName(e.target.value);
    }
    if (type === "lastName") {
      setLastName(e.target.value);
    }
    if (type === "dob") {
      setDob(e.target.value);
    }
    if (type === "address") {
      setAddress(e.target.value);
      setAddressError("");
    }
    if (type === "contact") {
      setContact(e.target.value);
      if (e.target.value.length === 10) {
        setContactError("");
      }
    }
    if (type === "allergies") {
      e.stopPropagation();
      let selectedOptions = [...selectedAllergies];
      if (selectedOptions.includes(e.target.value)) {
        selectedOptions.splice(selectedOptions.indexOf(e.target.value), 1);
      } else {
        selectedOptions = [...selectedAllergies, e.target.value];
      }

      setSelectedAllergies(selectedOptions);
    }
    if (type === "diagnosis") {
      setDiagnosis(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName && lastName && dob && address && contact) {
      if (address.length > 100) {
        setAddressError("Max number of characters allowed is 100");
        return;
      }
      if (contact.length !== 10) {
        setContactError("Enter valid contact with 10 digits");
        return;
      }
      setIsError(false);
      let data = {
        ...selectedMember,
        firstName,
        lastName,
        dob,
        address,
        contact,
        allergies: selectedAllergies.join(", "),
        diagnosis,
        benificiary: benificiaries,
      };
      onSubmit(data);
    } else {
      setIsError(true);
    }
  };
  const onCancelClick = (e) => {
    e.preventDefault();
    onCancel();
  };
  return (
    <div className="member-form-container">
      <h3 className="title">{type} Member</h3>
      <form onSubmit={handleSubmit}>
        <Input
          width={"250px"}
          value={firstName}
          onChange={(e) => onChange(e, "firstName")}
          error={isError && !firstName ? "Please enter first name" : ""}
          placeholder={"Enter First Name"}
          inputType={"First Name"}
          isDisabled={accessType !== "admin"}
        />
        <Input
          width={"250px"}
          value={lastName}
          onChange={(e) => onChange(e, "lastName")}
          error={isError && !lastName ? "Please enter last name" : ""}
          placeholder={"Enter Last Name"}
          inputType={"Last Name"}
          isDisabled={accessType !== "admin"}
        />
        <Input
          width={"250px"}
          value={dob}
          onChange={(e) => onChange(e, "dob")}
          error={isError && !dob ? "Please enter date of birth" : ""}
          placeholder={"Enter Date of Birth"}
          inputType={"Date of Birth"}
          isDisabled={accessType !== "admin"}
          type={"date"}
        />
        <TextBox
          width={"250px"}
          value={address}
          onChange={(e) => onChange(e, "address")}
          error={isError && !address ? "Please enter address" : addressError}
          placeholder={"Enter Address"}
          isDisabled={accessType !== "admin"}
          inputType={"Address"}
        />
        <Input
          width={"250px"}
          value={contact}
          onChange={(e) => onChange(e, "contact")}
          error={isError && !contact ? "Please enter contact" : contactError}
          placeholder={"Enter Contact"}
          inputType={"Contact"}
          isDisabled={accessType !== "admin"}
          type={"number"}
          maxLength={10}
        />
        <Checkbox
          options={allergiesOptions}
          onChange={(e) => onChange(e, "allergies")}
          selectedOptions={selectedAllergies}
          inputType={"Allergies"}
        />
        <TextBox
          width={"250px"}
          value={diagnosis}
          onChange={(e) => onChange(e, "diagnosis")}
          placeholder={"Enter Diagnosis"}
          isDisabled={accessType !== "admin"}
          inputType={"Diagnosis"}
        />
        <AddBenificiary data={benificiaries} setData={setBenificiaries} />
        <div className="button-container">
          <Button type="submit" width={"100%"} buttonType={"primary"}>
            Save Member
          </Button>
          <Button
            onClick={onCancelClick}
            width={"100%"}
            buttonType={"secondary"}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
export default MemberForm;
