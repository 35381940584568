import moment from "moment";
import "./membercard.css";

const MemberCard = ({
  member,
  onEdit,
  accessType,
  isDetailView,
  onMemberViewClick,
  onDelete,
}) => {
  return (
    <>
      {accessType === "member" || isDetailView ? (
        <MemberViewCard
          member={member}
          onEdit={onEdit}
          accessType={accessType}
        />
      ) : (
        <div
          className="member-card"
          key={member.memberId}
          onClick={onMemberViewClick}
        >
          <div className="member-logo">{`${member.firstName.charAt(
            0
          )}${member.lastName.charAt(0)}`}</div>
          <div className="info-container">
            <div
              className="delete-text"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(member.memberId);
              }}
            >
              Delete
            </div>
            <div className="info-left-container">
              <div className="member-info-text">
                <b>Name</b>: {member.firstName} {member.lastName}
              </div>
              <div className="member-info-text">
                <b>D.O.B</b>:{" "}
                {moment(new Date(member.dob)).format("MM/DD/YYYY")}
              </div>
              <div className="member-info-text">
                <b>Address</b>: {member.address}
              </div>
              <div className="member-info-text">
                <b>Contact</b>: {member.contact}
              </div>
            </div>
            <div className="info-right-container">
              <div className="member-info-text">
                <b>Allergies</b>: {member.allergies || "-"}
              </div>
              <div className="member-info-text">
                <b>Diagnosis</b>: {member.diagnosis || "-"}
              </div>
              <div className="member-info-text">
                <b>Benificiary</b>:{" "}
                {member.benificiary.length
                  ? member.benificiary.map((item, key) => (
                      <span key={key}>
                        Name: {item.name}, DOB: {item.dob}, Relation:{" "}
                        {item.relation}
                      </span>
                    ))
                  : "-"}
              </div>
            </div>
            {accessType === "admin" && (
              <div className="edit-text" onClick={(e) => onEdit(e, member)}>
                Edit
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const MemberViewCard = ({ member, onEdit, accessType }) => {
  return (
    <div className="memberview-card">
      <div className="memberview-logo">{`${member.firstName.charAt(
        0
      )}${member.lastName.charAt(0)}`}</div>
      <div className="memberview-details">
        <div className="member-info-text">
          <b>Name</b>: {member.firstName} {member.lastName}
        </div>
        <div className="member-info-text">
          <b>D.O.B</b>: {moment(new Date(member.dob)).format("MM/DD/YYYY")}
        </div>
        <div className="member-info-text">
          <b>Address</b>: {member.address}
        </div>
        <div className="member-info-text">
          <b>Contact</b>: {member.contact}
        </div>
        <div className="member-info-text">
          <b>Allergies</b>: {member.allergies}
        </div>
        <div className="member-info-text">
          <b>Diagnosis</b>: {member.diagnosis || "-"}
        </div>
        <div className="member-info-text">
          <b>Benificiary</b>:{" "}
          {member.benificiary.map((item, key) => (
            <span key={key}>
              Name: {item.name}, DOB: {item.dob}, Relation: {item.relation}
            </span>
          ))}
        </div>
        {accessType !== "nurse" && (
          <div className="edit-text" onClick={(e) => onEdit(e, member)}>
            Edit
          </div>
        )}
      </div>
    </div>
  );
};
export default MemberCard;
