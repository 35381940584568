import AuthForm from "./authform";

const EditAuth = ({ authData, onSubmit, onCancel, accessType }) => {
  return (
    <AuthForm
      formType="edit"
      onSubmit={onSubmit}
      authData={authData}
      onCancel={onCancel}
      accessType={accessType}
    />
  );
};
export default EditAuth;
