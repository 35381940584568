import { useState } from "react";
import { DropDown, Input, TextBox } from "../../components";
import { Button } from "../../components/button";

const typeOptions = [
  { label: "Individual", value: "individual" },
  { label: "Group", value: "group" },
];
const affiliationOptions = [
  { label: "Medicad", value: "medicad" },
  { label: "Medicare", value: "medicare" },
];
const networkOptions = [
  { label: "InNetwork", value: "InNetwork" },
  { label: "NonNetwork", value: "NonNetwork" },
];
const validationRules = {
  name: /^[A-Za-z.\s]+$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  speciality: /^[A-Za-z]+$/,
};
const ProviderForm = ({ type, selectedProvider = {}, onSubmit, onCancel }) => {
  const accessType = sessionStorage.getItem("accessType");
  const [name, setName] = useState(selectedProvider.name || "");
  const [nameError, setNameError] = useState("");
  const [location, setLocation] = useState(selectedProvider.location || "");
  const [locationError, setLocationError] = useState("");
  const [contact, setContact] = useState(selectedProvider.contact || "");
  const [contactError, setContactError] = useState("");
  const [email, setEmail] = useState(selectedProvider.email || "");
  const [emailError, setEmailError] = useState("");
  const [speciality, setSpeciality] = useState(
    selectedProvider.speciality || ""
  );
  const [specialityError, setSpecialityError] = useState("");
  const [network, setNetwork] = useState(
    selectedProvider.network || networkOptions[0].value
  );
  const [providerType, setProviderType] = useState(
    selectedProvider.providerType || typeOptions[0].value
  );
  const [affiliation, setAffiliation] = useState(
    selectedProvider.affiliation || affiliationOptions[0].value
  );
  const [isError, setIsError] = useState(false);

  const onChange = (e, type) => {
    if (type === "name") {
      setName(e.target.value);
      setNameError("");
    }
    if (type === "location") {
      setLocation(e.target.value);
      setLocationError("");
    }
    if (type === "contact") {
      setContact(e.target.value);
      setContactError("");
    }
    if (type === "email") {
      setEmail(e.target.value);
      setEmailError("");
    }
    if (type === "speciality") {
      setSpeciality(e.target.value);
      setSpecialityError("");
    }
    if (type === "network") {
      setNetwork(e.target.value);
    }
    if (type === "providerType") {
      setProviderType(e.target.value);
    }
    if (type === "affiliation") {
      setAffiliation(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && location && contact && email && speciality) {
      if (location.length > 100) {
        setLocationError("Max number of characters allowed is 100");
        return;
      }
      if (contact.length !== 10) {
        setContactError("Enter valid contact with 10 digits");
        return;
      }
      if (!validationRules.email.test(email)) {
        setEmailError("Enter valid email");
        return;
      }
      if (!validationRules.name.test(name)) {
        setNameError("Enter valid name");
        return;
      }
      if (!validationRules.speciality.test(speciality)) {
        setSpecialityError("Enter valid speciality");
        return;
      }
      setIsError(false);
      let data = {
        ...selectedProvider,
        name: name.toLowerCase().includes("dr.") ? name : `Dr. ${name}`,
        location,
        contact,
        email,
        speciality,
        network,
        providerType,
        affiliation,
      };
      onSubmit(data);
    } else {
      setIsError(true);
    }
  };
  const onCancelClick = (e) => {
    e.preventDefault();
    onCancel();
  };
  return (
    <div className="provider-form-container">
      <h3 className="title">{type} Provider</h3>
      <form onSubmit={handleSubmit}>
        <Input
          width={"250px"}
          value={name}
          onChange={(e) => onChange(e, "name")}
          error={isError && !name ? "Please enter name" : nameError}
          placeholder={"Enter Name"}
          inputType={"Name"}
          isDisabled={accessType !== "admin"}
        />
        <TextBox
          width={"250px"}
          value={location}
          onChange={(e) => onChange(e, "location")}
          error={isError && !location ? "Please enter location" : locationError}
          placeholder={"Enter Location"}
          isDisabled={accessType !== "admin"}
          inputType={"Location"}
        />
        <Input
          width={"250px"}
          value={speciality}
          onChange={(e) => onChange(e, "speciality")}
          error={
            isError && !speciality ? "Please enter speciality" : specialityError
          }
          placeholder={"Enter Speciality"}
          inputType={"Speciality"}
          isDisabled={accessType !== "admin"}
        />
        <DropDown
          width={"270px"}
          value={providerType}
          onChange={(e) => onChange(e, "providerType")}
          options={typeOptions}
          isDisabled={accessType !== "admin"}
          inputType={"Type"}
        />
        <Input
          width={"250px"}
          value={contact}
          onChange={(e) => onChange(e, "contact")}
          error={isError && !contact ? "Please enter contact" : contactError}
          placeholder={"Enter Contact"}
          inputType={"Contact"}
          isDisabled={accessType !== "admin"}
          type={"number"}
        />
        <Input
          width={"250px"}
          value={email}
          onChange={(e) => onChange(e, "email")}
          error={isError && !email ? "Please enter email" : emailError}
          placeholder={"Enter Email"}
          inputType={"Email"}
          isDisabled={accessType !== "admin"}
          type={"email"}
        />
        <DropDown
          width={"270px"}
          value={network}
          onChange={(e) => onChange(e, "network")}
          inputType={"Network"}
          isDisabled={accessType !== "admin"}
          options={networkOptions}
        />
        <DropDown
          width={"270px"}
          value={affiliation}
          onChange={(e) => onChange(e, "affiliation")}
          inputType={"Affiliation"}
          isDisabled={accessType !== "admin"}
          options={affiliationOptions}
        />
        <div className="button-container">
          <Button type="submit" width={"100%"} buttonType={"primary"}>
            Save Provider
          </Button>
          <Button
            onClick={onCancelClick}
            width={"100%"}
            buttonType={"secondary"}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ProviderForm;
