import React, { useState } from "react";
import "./member.css";
import { Input } from "../../components";
import moment from "moment";

const AddBenificiary = ({ data, setData }) => {
  const [benificiaries, setBenificiaries] = useState(data || []);
  const benificiaryObject = {
    name: "",
    dob: "",
    relation: "",
  };
  useState(() => {
    setBenificiaries(data);
  }, [data]);
  const addBenificiary = () => {
    let benificiary = [...benificiaries, benificiaryObject];
    setBenificiaries(benificiary);
    setData(benificiary);
  };
  const onChange = (e, type, index) => {
    let benificiary = benificiaries[index];
    let allbenificiaries = [...benificiaries];
    if (type === "name") {
      benificiary.name = e.target.value;
    }
    if (type === "dob") {
      benificiary.dob = e.target.value;
    }
    if (type === "relation") {
      benificiary.relation = e.target.value;
    }
    allbenificiaries.splice(index, 1, benificiary);
    setBenificiaries(allbenificiaries);
    setData(allbenificiaries);
  };
  const removeBenificiary = (index) => {
    let allbenificiaries = [...benificiaries];
    allbenificiaries.splice(index, 1);
    setBenificiaries(allbenificiaries);
    setData(allbenificiaries);
  };
  return (
    <div>
      <p className="benificiary-title">
        Benificiaries{" "}
        {benificiaries.length < 2 && (
          <span className="add-benificiary" onClick={addBenificiary}>
            +Add Benificiary
          </span>
        )}
      </p>
      {benificiaries.length
        ? benificiaries.map((benificiary, index) => (
            <React.Fragment key={index}>
              <p className="benificiary-label">
                Benificiary {index + 1}{" "}
                <span
                  className="add-benificiary"
                  onClick={() => removeBenificiary(index)}
                >
                  Remove
                </span>
              </p>
              <Input
                width={"250px"}
                value={benificiary.name}
                onChange={(e) => onChange(e, "name", index)}
                placeholder={"Enter Name"}
                inputType={"Name"}
              />
              <Input
                width={"250px"}
                value={moment(new Date(benificiary.dob)).format("YYYY-MM-DD")}
                onChange={(e) => onChange(e, "dob", index)}
                placeholder={"Enter Date of Birth"}
                inputType={"Date of Birth"}
                type={"date"}
              />
              <Input
                width={"250px"}
                value={benificiary.relation}
                onChange={(e) => onChange(e, "relation", index)}
                placeholder={"Enter Relation"}
                inputType={"Relation"}
              />
            </React.Fragment>
          ))
        : ""}
    </div>
  );
};
export default AddBenificiary;
