import moment from "moment";
import { Input, TextBox, Button, DropDown } from "../../components";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";

const statusOptions = [
  { label: "Open", value: "open" },
  { label: "InProgress", value: "inProgress" },
  { label: "Closed", value: "closed" },
];
const diagnosisCodeRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{3}$/;
const AuthForm = ({ formType, onSubmit, authData, onCancel, accessType }) => {
  const [admissionDate, setAdmissionDate] = useState(
    authData
      ? moment(new Date(authData.admissionDate)).format("YYYY-MM-DD")
      : ""
  );
  const [dischargeDate, setDisChargeDate] = useState(
    authData
      ? moment(new Date(authData.dischargeDate)).format("YYYY-MM-DD")
      : ""
  );
  const [diagnosisCode, setDiagnosisCode] = useState(
    authData?.diagnosisCode || ""
  );
  const [diagnosis, setDiagnosis] = useState(authData?.diagnosis || "");
  const [provider, setProvider] = useState(authData?.providerId || "");
  const [providerOptions, setProviderOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    authData?.status || statusOptions[0].value
  );
  const [admissionDateError, setAdmissionDateError] = useState("");
  const [dischargeDateError, setDischargeDateError] = useState("");
  const [diagnosisCodeError, setDiagnosisCodeError] = useState("");
  const [isError, setIsError] = useState(false);
  const { userData } = useContext(AppContext);
  useEffect(() => {
    if (userData && userData.providers?.length) {
      let providersList = userData.providers.map((item) => {
        return { ...item, label: item.name, value: item.providerId };
      });
      setProviderOptions(providersList);
      if (!provider) {
        setProvider(providersList[0].providerId);
      }
    }
  }, [userData, provider]);

  const checkDate = (aDate, dDate) => {
    if (dDate && moment(dDate).diff(aDate) < 0) {
      setAdmissionDateError(
        "Admission date should be less than discharge date"
      );
    } else {
      setAdmissionDateError("");
    }
    if (aDate && moment(aDate).diff(dDate) > 0) {
      setDischargeDateError(
        "Discharge date should be greater than admmission date"
      );
    } else {
      setDischargeDateError("");
    }
  };
  const onChange = (e, type) => {
    if (type === "admissionDate") {
      setAdmissionDate(e.target.value);
      checkDate(e.target.value, dischargeDate);
    }
    if (type === "dischargeDate") {
      setDisChargeDate(e.target.value);
      checkDate(admissionDate, e.target.value);
    }
    if (type === "diagnosisCode") {
      setDiagnosisCode(e.target.value);
      setDiagnosisCodeError("");
    }
    if (type === "diagnosis") {
      setDiagnosis(e.target.value);
    }
    if (type === "provider") {
      setProvider(e.target.value);
    }
    if (type === "status") {
      setSelectedStatus(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsError(false);
    if (
      diagnosisCode &&
      admissionDate &&
      diagnosis &&
      dischargeDate &&
      !dischargeDateError &&
      !admissionDateError
    ) {
      if (!diagnosisCodeRegex.test(diagnosisCode)) {
        setDiagnosisCodeError("Enter valid code");
        return;
      }
      setIsError(false);
      const data = {
        ...authData,
        admissionDate,
        dischargeDate,
        diagnosisCode,
        diagnosis,
        providerId: provider,
        status: selectedStatus,
      };
      onSubmit(data);
    } else {
      setIsError(true);
    }
  };
  return (
    <div className="Auth-form-container">
      <h3 className="title">{formType} Authorization</h3>
      <form onSubmit={handleSubmit}>
        <DropDown
          inputType={"Status"}
          options={statusOptions}
          onChange={(e) => onChange(e, "status")}
          value={selectedStatus}
        />
        <Input
          width={"250px"}
          value={admissionDate}
          onChange={(e) => onChange(e, "admissionDate")}
          error={
            isError && !admissionDate
              ? "Select Admission Date"
              : admissionDateError
          }
          placeholder={"Select Admission Date"}
          inputType={"Admission Date"}
          isDisabled={accessType !== "admin"}
          type={"date"}
        />
        <Input
          width={"250px"}
          value={dischargeDate}
          onChange={(e) => onChange(e, "dischargeDate")}
          error={
            isError && !dischargeDate
              ? "Select Discharge Date"
              : dischargeDateError
          }
          placeholder={"Select Discharge Date"}
          inputType={"Discharge Date"}
          isDisabled={accessType !== "admin"}
          type={"date"}
        />
        <Input
          width={"250px"}
          value={diagnosisCode}
          onChange={(e) => onChange(e, "diagnosisCode")}
          error={
            isError && !diagnosisCode
              ? "Enter Diagnosis Code"
              : diagnosisCodeError
          }
          placeholder={"Enter Diagnosis Code"}
          inputType={"Diagnosis Code"}
          isDisabled={accessType !== "admin"}
        />

        <TextBox
          width={"250px"}
          value={diagnosis}
          onChange={(e) => onChange(e, "diagnosis")}
          error={isError && !diagnosis ? "Enter Diagnosis" : ""}
          placeholder={"Enter Diagnosis"}
          isDisabled={accessType !== "admin"}
          inputType={"Diagnosis"}
        />
        <DropDown
          inputType={"Provider"}
          options={providerOptions}
          onChange={(e) => onChange(e, "provider")}
          value={provider}
        />
        <div className="button-container">
          <Button type="submit" width={"100%"} buttonType={"primary"}>
            Save
          </Button>
          <Button onClick={onCancel} width={"100%"} buttonType={"secondary"}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AuthForm;
