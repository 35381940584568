import axios from "axios";

class ApiService {
  baseUrl = "http://localhost:5000/api";
  getMembers = async () => {
    return await axios.get(this.baseUrl + "/members");
  };
  getProviders = async () => {
    return await axios.get(this.baseUrl + "/providers");
  };
  deleteMember = async (memberId) => {
    return await axios.delete(this.baseUrl + "/members/" + memberId);
  };
}
const apiService = new ApiService();
export default apiService;
