import "./loader.css";

const Loader = ({ size }) => {
  return (
    <div
      className="loader"
      style={{
        width: size + "px",
        height: size + "px",
        borderWidth: size / 5 + "px",
      }}
    />
  );
};
export default Loader;
