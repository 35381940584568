import React, { useContext, useEffect, useState } from "react";
import { ProviderCard, SecondaryHeader } from "../../components";
import { useNavigate } from "react-router-dom";
import "./provider.css";
import EditProvider from "./editprovider";
import AddProvider from "./addprovider";
import AdvanceSearchModal from "./searchmodal";
import { AppContext } from "../../AppContext/AppContext";

const Provider = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const accessType = sessionStorage.getItem("accessType");
  const { userData, setUserData } = useContext(AppContext);
  const [providerslist, setProvidersList] = useState([]);
  const [allProviders, setAllProviders] = useState([]);
  const [showAdvSearchModal, setShowAdvSearchModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.providers?.length) {
      setAllProviders(userData.providers);
      setProvidersList(userData.providers);
    }
  }, [userData?.providers]);

  const onEdit = (e, provider) => {
    e.stopPropagation();
    setIsEdit(true);
    setSelectedProvider(provider);
  };
  const onAdd = () => {
    setIsAdd(true);
  };
  const setContextData = (newData) => {
    let currentData = { ...userData };
    currentData.providers = newData;
    setUserData(currentData);
  };
  const onSubmit = (data) => {
    let newProvidersList = [...providerslist];
    if (isAdd) {
      data.providerId = "P" + Math.floor(100000000 + Math.random() * 900000000);
      newProvidersList = [...providerslist, data];
      setIsAdd(false);
    }
    if (isEdit) {
      setIsEdit(false);
      let selectedProviderIndex = providerslist.findIndex(
        (item) => item.id === data.id
      );
      newProvidersList.splice(selectedProviderIndex, 1, data);
    }
    setProvidersList(newProvidersList);
    setAllProviders(newProvidersList);
    setContextData(newProvidersList);
  };
  const onProviderViewClick = (provider) => {
    let data = { provider: provider, accessType: accessType };
    navigate(`/provider/${provider.providerId}/detail`, {
      state: JSON.stringify(data),
    });
  };
  const onProviderSearch = (value, type) => {
    const searchRegex = new RegExp(value, "i");
    const searchItem = type;
    const filteredList = allProviders.filter((item) =>
      searchRegex.test(item[searchItem])
    );
    setProvidersList(filteredList);
    setShowAdvSearchModal(false);
  };
  const onAdvanceSearch = () => {
    setShowAdvSearchModal(true);
  };
  const onDelete = (id) => {
    let providersData = [...providerslist];
    const deleteIndex = providersData.findIndex(
      (item) => item.providerId === id
    );
    if (deleteIndex > -1) {
      providersData.splice(deleteIndex, 1);
    }
    setProvidersList(providersData);
    setAllProviders(providersData);
    setContextData(providersData);
  };
  return (
    <div>
      <SecondaryHeader
        currentPage={"provider"}
        onSearch={(e) => onProviderSearch(e.target.value, "providerId")}
        onAdvanceSearch={onAdvanceSearch}
      />
      <div className="providers-container">
        {!isEdit && !isAdd && (
          <>
            <div className="provider-title-container">
              {accessType !== "provider" ? (
                <>
                  <span className="title">
                    Providers ({providerslist.length})
                  </span>
                  {accessType === "admin" && (
                    <span className="add-text" onClick={onAdd}>
                      +Add Provider
                    </span>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            {providerslist.length && accessType !== "provider"
              ? providerslist.map((provider) => (
                  <ProviderCard
                    key={provider.providerId}
                    provider={provider}
                    onEdit={onEdit}
                    accessType={accessType}
                    onProviderViewClick={() => onProviderViewClick(provider)}
                    onDelete={onDelete}
                  />
                ))
              : "No Providers Found"}
          </>
        )}
        {isEdit && (
          <EditProvider
            selectedProvider={selectedProvider}
            onSubmit={onSubmit}
            onCancel={() => setIsEdit(false)}
          />
        )}
        {isAdd && (
          <AddProvider onSubmit={onSubmit} onCancel={() => setIsAdd(false)} />
        )}
        {showAdvSearchModal && (
          <AdvanceSearchModal
            onSearch={onProviderSearch}
            onCancel={() => setShowAdvSearchModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Provider;
