export const providersData = [
  {
    providerType: "individual",
    affiliation: "Medicad",
    providerId: "P648952478",
    name: "Dr. Mohan",
    location: "Gachibowli, Hyderabad - 500032",
    speciality: "ENT",
    contact: "8887775554",
    email: "dr.mohan@gmail.com",
    network: "NonNetwork",
  },
  {
    providerType: "group",
    affiliation: "Medicad",
    providerId: "P648952477",
    name: "Dr. Latha",
    location: "Manikonda, Hyderabad - 500089",
    speciality: "Nephrologist",
    contact: "8885554127",
    email: "dr.latha@gmail.com",
    network: "InNetwork",
  },
  {
    providerType: "individual",
    affiliation: "Medicare",
    providerId: "P648952476",
    name: "Dr. Krishna",
    location: "Musherabad, Hyderabad - 500061",
    speciality: "Urologist",
    contact: "8854659878",
    email: "dr.krishna@gmail.com",
    network: "NonNetwork",
  },
  {
    providerType: "group",
    affiliation: "Medicad",
    providerId: "P648952475",
    name: "Dr. Mahesh",
    location: "Gachibowli, Hyderabad - 500032",
    speciality: "Orthopedic",
    contact: "8878451278",
    email: "dr.mahesh@gmail.com",
    network: "InNetwork",
  },
  {
    providerType: "individual",
    affiliation: "Medicare",
    providerId: "P648952474",
    name: "Dr. Gopi",
    location: "Manikonda, Hyderabad - 500089",
    speciality: "Neurologist",
    contact: "8712357835",
    email: "dr.gopi@gmail.com",
    network: "InNetwork",
  },
  {
    providerType: "group",
    affiliation: "Medicare",
    providerId: "P648952473",
    name: "Dr. Sneha",
    location: "Musherabad, Hyderabad - 500061",
    speciality: "Diabetalogist",
    contact: "8354578264",
    email: "dr.sneha@gmail.com",
    network: "NonNetwork",
  },
];
