import React, { useEffect, useState } from "react";
import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [currentPage, setCurrentPage] = useState("member");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/" || location.pathname.includes("/member")) {
      setCurrentPage("member");
    } else if (location.pathname.includes("/provider")) {
      setCurrentPage("provider");
    }
  }, [location.pathname]);
  const onNavigate = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <div className="header">
      <div className="navbar">
        <div className="navbar-item">
          <h2>Centene</h2>
        </div>
        <div
          className={`navbar-item ${
            currentPage === "member" ? "selected" : ""
          }`}
        >
          <a
            className="navbar-link"
            href="/member"
            onClick={(e) => onNavigate(e, "/member")}
          >
            Members
          </a>
        </div>
        <div
          className={`navbar-item ${
            currentPage === "provider" ? "selected" : ""
          }`}
        >
          <a
            className="navbar-link"
            href="/provider"
            onClick={(e) => onNavigate(e, "/provider")}
          >
            Provider
          </a>
        </div>
        {/* <div
          className={`navbar-item ${currentPage === "auth" ? "selected" : ""}`}
        >
          <a className="navbar-link" href="/auth">
            Authorization
          </a>
        </div> */}
      </div>

      <a className="logout" href="/login">
        Logout
      </a>
    </div>
  );
};

export default Header;
