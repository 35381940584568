import React, { useContext, useEffect, useState } from "react";
import { DropDown, Input } from "../../components";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";
import { members } from "../../constants/members";
import { providersData } from "../../constants/providers";

const loginOptions = [
  { label: "Admin", value: "admin" },
  { label: "Nurse", value: "nurse" },
  { label: "Member", value: "member" },
];
const Login = () => {
  const [userId, setUserName] = useState("");
  const [userIdError, setUserNameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginType, setLoginType] = useState(loginOptions[0].value);
  const navigate = useNavigate();
  const { setUserData } = useContext(AppContext);
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const onChange = (e, type) => {
    if (type === "name") {
      setUserNameError("");
      setUserName(e.target.value);
    } else if (type === "password") {
      setPasswordError("");
      setPassword(e.target.value);
    } else if (type === "loginType") {
      setLoginType(e.target.value);
    }
  };
  const userRegex = {
    admin: /^(?=.*\d)(A[a-zA-Z0-9]{5})$/,
    nurse: /^(?=.*\d)(N[a-zA-Z0-9]{5})$/,
    member: /^(?=.*\d)(MEM[a-zA-Z0-9]{5})$/,
    password: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8}$/,
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (userId && password && userRegex.password.test(password)) {
      if (loginType === "admin" && userRegex.admin.test(userId)) {
        sessionStorage.setItem("accessType", loginType);
      } else if (loginType === "nurse" && userRegex.nurse.test(userId)) {
        sessionStorage.setItem("accessType", loginType);
      } else if (loginType === "member" && userRegex.member.test(userId)) {
        sessionStorage.setItem("accessType", loginType);
      } else {
        setUserNameError("Enter valid user name");
        return;
      }
      sessionStorage.setItem("token", "12345678");
      setUserData({
        members: members,
        providers: providersData,
      });
      navigate("/member");
    } else if (!userId) {
      setUserNameError("Please  enter user name");
    } else if (!userRegex.password.test(password)) {
      setPasswordError("Enter valid password");
    } else if (!password) {
      setPasswordError("Please enter password");
    }
  };
  return (
    <div className="login-container">
      <div className="login-card">
        <h2 style={{ color: "#1c1ca7" }}>Login to Centene</h2>
        <form onSubmit={onSubmit}>
          <DropDown
            inputType={""}
            options={loginOptions}
            onChange={(e) => onChange(e, "loginType")}
            value={loginType}
            placeholder="Select login type"
          />
          <Input
            value={userId}
            onChange={(e) => onChange(e, "name")}
            error={userIdError}
            type={"text"}
            placeholder={"User Id"}
          />
          <Input
            value={password}
            onChange={(e) => onChange(e, "password")}
            error={passwordError}
            type={"password"}
            placeholder={"Password"}
          />
          <div>
            <button type="submit" className="loginbutton">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
