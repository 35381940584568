import ProviderForm from "./providerform";

const EditProvider = ({ selectedProvider, onSubmit, onCancel }) => {
  return (
    <ProviderForm
      type="edit"
      selectedProvider={selectedProvider}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};
export default EditProvider;
