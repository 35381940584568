import React, { useContext, useEffect, useState } from "react";
import "./member.css";
import { MemberCard, SecondaryHeader } from "../../components";
import EditMember from "./editmember";
import AddMember from "./addmember";
import { useNavigate } from "react-router-dom";
import MemberDetail from "../memberdetail/memberdetail";
import { AppContext } from "../../AppContext/AppContext";
import apiService from "../../service/ApiService";

const Member = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const accessType = sessionStorage.getItem("accessType");
  const { userData, setUserData } = useContext(AppContext);
  const [memberslist, setMembersList] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.members?.length) {
      setAllMembers(userData.members);
      setMembersList(userData.members);
    }
  }, [userData?.members]);
  const onEdit = (e, member) => {
    e.stopPropagation();
    setIsEdit(true);
    setSelectedMember(member);
  };
  const onAdd = () => {
    setIsAdd(true);
  };
  const setContextData = (newData) => {
    let currentData = { ...userData };
    currentData.members = newData;
    setUserData(currentData);
  };
  const onSubmit = (data) => {
    let newMemebersList = [...memberslist];
    if (isAdd) {
      setIsAdd(false);
      data.memberId = "MEM" + Math.floor(100000 + Math.random() * 900000);
      data.authHistory = [];
      newMemebersList = [...memberslist, data];
    }
    if (isEdit) {
      setIsEdit(false);
      let selectedMemberIndex = memberslist.findIndex(
        (item) => item.memberId === data.memberId
      );
      newMemebersList.splice(selectedMemberIndex, 1, data);
    }
    setMembersList(newMemebersList);
    setAllMembers(newMemebersList);
    setContextData(newMemebersList);
  };
  const onMemberViewClick = (member) => {
    let data = { member: member, accessType: accessType };
    navigate(`/member/${member.memberId}/detail`, {
      state: JSON.stringify(data),
    });
  };
  const onSearch = (e) => {
    const searchRegex = new RegExp(e.target.value, "i");
    let filteredMembers = allMembers.filter((item) =>
      searchRegex.test(`${item.firstName} ${item.lastName}`)
    );
    setMembersList(filteredMembers);
  };
  const onDelete = (id) => {
    let membersData = [...memberslist];
    const deleteIndex = membersData.findIndex((item) => item.memberId === id);
    if (deleteIndex > -1) {
      membersData.splice(deleteIndex, 1);
    }
    setAllMembers(membersData);
    setMembersList(membersData);
    setContextData(membersData);
    apiService.deleteMember(id);
  };
  return (
    <>
      {accessType === "member" && memberslist.length ? (
        <MemberDetail
          memberAccessType={accessType}
          memberData={memberslist[0]}
        />
      ) : (
        <>
          <SecondaryHeader currentPage={"members"} onSearch={onSearch} />
          <div className="members-container">
            {!isEdit && !isAdd && (
              <>
                <div className="title-container">
                  {accessType !== "member" ? (
                    <>
                      <span className="title">
                        Members ({memberslist.length})
                      </span>
                      {accessType === "admin" && (
                        <span className="add-text" onClick={onAdd}>
                          +Add Member
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {memberslist.length && accessType !== "member"
                  ? memberslist.map((member) => (
                      <MemberCard
                        key={member.memberId}
                        member={member}
                        onEdit={onEdit}
                        accessType={accessType}
                        onMemberViewClick={() => onMemberViewClick(member)}
                        onDelete={onDelete}
                      />
                    ))
                  : "No Members Found"}
              </>
            )}

            {isEdit && (
              <EditMember
                selectedMember={selectedMember}
                onSubmit={onSubmit}
                onCancel={() => setIsEdit(false)}
              />
            )}
            {isAdd && (
              <AddMember onSubmit={onSubmit} onCancel={() => setIsAdd(false)} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Member;
