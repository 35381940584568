import "./checkbox.css";

const Checkbox = ({ options, onChange, selectedOptions, inputType, error }) => {
  return (
    <div className="checkbox-container">
      <label className="input-label">{inputType}</label>
      {options.map((option, key) => (
        <div className="checkbox" key={key}>
          <input
            className="checkbox-input"
            type="checkbox"
            onChange={onChange}
            value={option}
            checked={selectedOptions.includes(option)}
          />
          <input
            className="hidden-checkbox-input"
            type="checkbox"
            onChange={onChange}
            value={option}
            checked={selectedOptions.includes(option)}
          />
          <label className="checkbox-label">{option}</label>
        </div>
      ))}
      {error && <p className="errortext">{error}</p>}
    </div>
  );
};
export default Checkbox;
