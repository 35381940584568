import "./input.css";

const Input = ({
  value,
  onChange,
  error,
  type,
  width,
  placeholder,
  inputType,
  height,
  isDisabled = false,
  maxLength,
}) => {
  return (
    <div className="input-container">
      <label className="input-label">{inputType}</label>
      <input
        style={{ width: width, height: height }}
        className={`input ${error ? "error" : ""}`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isDisabled}
        maxLength={maxLength}
      />
      {error && <p className="errortext">{error}</p>}
    </div>
  );
};
export default Input;
