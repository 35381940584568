import { useState } from "react";
import { Button, DropDown, Input } from "../../components";
import "./provider.css";

const AdvanceSearchModal = ({ onSearch, onCancel }) => {
  const dropdownOptions = [
    { label: "Name", value: "name" },
    { label: "Speciality", value: "speciality" },
    { label: "Location", value: "location" },
    { label: "Network", value: "network" },
    { label: "Type", value: "providerType" },
    { label: "Affiliation", value: "affiliation" },
  ];
  const [selectedOption, setSelectedOption] = useState(
    dropdownOptions[0].value
  );
  const [searchValue, setSearchValue] = useState("");

  const onChange = (e, type) => {
    if (type === "searchType") {
      setSelectedOption(e.target.value);
    }
    if (type === "searchvalue") {
      setSearchValue(e.target.value);
    }
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div className="modal">
        <div className="modal-container">
          <div className="modal-header">Advanced Search</div>
          <div className="modal-content">
            <DropDown
              options={dropdownOptions}
              value={selectedOption}
              onChange={(e) => onChange(e, "searchType")}
              width={"100%"}
            />
            <Input
              placeholder={"Enter Search Value"}
              value={searchValue}
              onChange={(e) => onChange(e, "searchvalue")}
              width={"240px"}
            />
            <Button
              onClick={() => onSearch(searchValue, selectedOption)}
              width={"100%"}
              buttonType={"primary"}
            >
              Search
            </Button>
            <Button onClick={onCancel} width={"100%"} buttonType={"secondary"}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvanceSearchModal;
