import "./providercard.css";

const ProviderCard = ({
  provider,
  onEdit,
  accessType,
  isDetailView,
  onProviderViewClick,
  onDelete,
}) => {
  return (
    <>
      {accessType === "provider" || isDetailView ? (
        <ProviderViewCard
          provider={provider}
          onEdit={onEdit}
          accessType={accessType}
        />
      ) : (
        <div
          className="provider-card"
          key={provider.providerId}
          onClick={onProviderViewClick}
        >
          <div className="provider-logo">{`${provider.name
            .split(". ")[1]
            .charAt(0)}`}</div>
          <div className="provider-info-container">
            <div
              className="provider-delete-text"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(provider.providerId);
              }}
            >
              Delete
            </div>
            <div className="provider-info-left-container">
              <p>
                <b>Provider Id</b>: {provider.providerId}
              </p>
              <p>
                <b>Name</b>: {provider.name}
              </p>

              <p>
                <b>location</b>: {provider.location}
              </p>
              <p>
                <b>Speciality</b>: {provider.speciality}
              </p>
              <p>
                <b>Type</b>: {provider.providerType}
              </p>
            </div>
            <div className="provider-info-right-container">
              <p>
                <b>Contact</b>: {provider.contact}
              </p>
              <p className="email">
                <b>Email</b>: {provider.email}
              </p>
              <p>
                <b>Network</b>: {provider.network}
              </p>
              <p>
                <b>Affiliation </b>: {provider.affiliation}
              </p>
            </div>
            {accessType === "admin" && (
              <div
                className="provider-edit-text"
                onClick={(e) => onEdit(e, provider)}
              >
                Edit
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ProviderViewCard = ({ provider, onEdit, accessType }) => {
  return (
    <div className="providerview-card">
      <div className="providerview-logo">{`${provider.name
        .split(". ")[1]
        .charAt(0)}`}</div>
      <div className="providerview-details">
        <p>
          <b>Provider Id</b>: {provider.providerId}
        </p>
        <p>
          <b>Name</b>: {provider.name}
        </p>

        <p>
          <b>location</b>: {provider.location}
        </p>
        <p>
          <b>Speciality</b>: {provider.speciality}
        </p>
        <p>
          <b>Type</b>: {provider.providerType}
        </p>
        <p>
          <b>Contact</b>: {provider.contact}
        </p>
        <p className="email">
          <b>Email</b>: {provider.email}
        </p>

        <p>
          <b>Network</b>: {provider.network}
        </p>
        <p>
          <b>Affiliation </b>: {provider.affiliation}
        </p>
        {accessType !== "nurse" && (
          <div
            className="provider-edit-text"
            onClick={(e) => onEdit(e, provider)}
          >
            Edit
          </div>
        )}
      </div>
    </div>
  );
};
export default ProviderCard;
