import { useLocation, useParams } from "react-router-dom";
import { MemberCard, SecondaryHeader } from "../../components";
import { useContext, useEffect, useState } from "react";
import EditMember from "../member/editmember";
import "./memberdetail.css";
import { AppContext } from "../../AppContext/AppContext";

const MemberDetail = ({ memberData, memberAccessType }) => {
  const location = useLocation();
  const locationStateInfo = JSON.parse(location.state);
  const params = useParams();
  const memberId = params.id;
  const [member, setMember] = useState(
    memberData || locationStateInfo?.member || {}
  );
  const accessType = memberAccessType || locationStateInfo?.accessType;
  const [isEdit, setIsEdit] = useState(false);
  const { userData, setUserData } = useContext(AppContext);
  useEffect(() => {
    if (userData?.members?.length && memberId) {
      const index = userData.members.findIndex(
        (item) => item.memberId === memberId
      );
      setMember(userData.members[index]);
    }
  }, [userData, memberId]);
  const onEdit = () => {
    setIsEdit(true);
  };
  const onSubmit = (data) => {
    setMember(data);
    let userInfo = { ...userData };
    const currentProviderIndex = userInfo?.members?.findIndex(
      (item) => item.providerId === data.providerId
    );
    if (currentProviderIndex > -1) {
      userInfo.members[currentProviderIndex] = data;
      setUserData(userInfo);
    }
    setIsEdit(false);
  };
  return (
    <>
      <SecondaryHeader
        currentPage={"member-detail"}
        member={member}
        accessType={accessType}
      />
      <div className="memberdetail-container">
        {!isEdit ? (
          <MemberCard
            isDetailView
            member={member}
            accessType={accessType}
            onEdit={onEdit}
          />
        ) : (
          <EditMember
            selectedMember={member}
            onSubmit={onSubmit}
            onCancel={() => setIsEdit(false)}
          />
        )}
      </div>
    </>
  );
};
export default MemberDetail;
