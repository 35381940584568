import "./button.css";

const Button = ({ type, buttonType, onClick, width, children }) => {
  return (
    <button
      style={{ width: width }}
      className={`button ${buttonType}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
export default Button;
