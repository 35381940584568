import { useLocation } from "react-router-dom";
import { ProviderCard } from "../../components";
import { useContext, useState } from "react";
import EditProvider from "../provider/editprovider";
import "./providerdetail.css";
import { AppContext } from "../../AppContext/AppContext";

const ProviderDetail = ({ providerData, providerAccessType }) => {
  const location = useLocation();
  const locationStateInfo = JSON.parse(location.state);
  const { userData, setUserData } = useContext(AppContext);
  const [provider, setProvider] = useState(
    providerData || locationStateInfo.provider
  );
  const accessType = providerAccessType || locationStateInfo.accessType;
  const [isEdit, setIsEdit] = useState(false);
  const onEdit = () => {
    setIsEdit(true);
  };
  const onSubmit = (data) => {
    setProvider(data);
    let userInfo = { ...userData };
    const currentProviderIndex = userInfo?.providers?.findIndex(
      (item) => item.providerId === data.providerId
    );
    if (currentProviderIndex > -1) {
      userInfo.providers[currentProviderIndex] = data;
      setUserData(userInfo);
    }
    setIsEdit(false);
  };
  return (
    <>
      <div className="providerdetail-container">
        {!isEdit ? (
          <ProviderCard
            isDetailView
            provider={provider}
            accessType={accessType}
            onEdit={onEdit}
          />
        ) : (
          <EditProvider
            selectedProvider={provider}
            onSubmit={onSubmit}
            onCancel={() => setIsEdit(false)}
          />
        )}
      </div>
    </>
  );
};
export default ProviderDetail;
