import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Authorization, Home, Login, Member, Provider } from "./screens";
import { AppRoute } from "./AppRoute";
import MemberDetail from "./screens/memberdetail/memberdetail";
import { AuthDetail } from "./screens/authorization";
import ProviderDetail from "./screens/providerdetail/providerdetail";
import AppProvider from "./AppContext/AppContext";

function App() {
  const routes = [
    {
      path: "/",
      component: Home,
      isProtected: true,
    },
    {
      path: "/login",
      component: Login,
      isProtected: false,
    },
    {
      path: "/member",
      component: Member,
      isProtected: true,
    },
    {
      path: "/provider",
      component: Provider,
      isProtected: true,
    },
    {
      path: "/provider/:id/detail/",
      component: ProviderDetail,
      isProtected: true,
    },
    {
      path: "/member/:id/detail/",
      component: MemberDetail,
      isProtected: true,
    },
    {
      path: "/member/:id/auth",
      component: Authorization,
      isProtected: true,
    },
    {
      path: "/member/:id/auth/:authId",
      component: AuthDetail,
      isProtected: true,
    },
  ];
  return (
    <div className="App">
      <AppProvider>
        <Router>
          <AppRoute routes={routes} />
        </Router>
      </AppProvider>
    </div>
  );
}

export default App;
