import "./dropdown.css";

const DropDown = ({
  inputType,
  options,
  onChange,
  value,
  error,
  isDisabled,
  width,
}) => {
  return (
    <div className="dropdown-container" style={{ width: width }}>
      <label className="dropdown-label">{inputType}</label>
      <select
        className="dropdown"
        disabled={isDisabled}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option
            className={`dropdown-option ${
              value === option.value ? "selected" : ""
            }`}
            value={option.value}
            key={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="errortext">{error}</p>}
    </div>
  );
};
export default DropDown;
