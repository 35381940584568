import React, { useContext, useEffect, useState } from "react";
import { SecondaryHeader } from "../../components";
import "./authorization.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import AddAuth from "./addauth";
import { AppContext } from "../../AppContext/AppContext";

const Authorization = () => {
  const location = useLocation();
  const params = useParams();
  const memberId = params.id;
  const locationStateInfo = JSON.parse(location.state);
  const accessType = locationStateInfo?.accessType;
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState(false);
  const [authData, setAuthData] = useState([]);

  const { userData, setUserData } = useContext(AppContext);

  useEffect(() => {
    if (userData && userData.members?.length) {
      let currentMember = userData.members.find(
        (item) => item.memberId === memberId
      );
      if (currentMember) setAuthData(currentMember?.authHistory);
    }
  }, [userData, memberId]);

  const onNavigate = (e, id) => {
    e.preventDefault();
    navigate(`/member/${memberId}/auth/${id}`, { state: location.state });
  };
  const setContextData = (newData) => {
    let currentData = { ...userData };
    let currentMemberIndex = userData.members.findIndex(
      (item) => item.memberId === memberId
    );
    currentData.members[currentMemberIndex].authHistory = newData;
    setUserData(currentData);
  };
  const onSubmit = (data) => {
    let idPrefix = "IP";
    if (
      moment(new Date(data.dischargeDate)).diff(
        new Date(data.admissionDate),
        "days"
      ) < 1
    ) {
      idPrefix = "OP";
    }
    setIsAdd(false);
    data = {
      ...data,
      createdDate: moment().format("MM/DD/YYYY"),
      authId: idPrefix + Math.floor(1000000 + Math.random() * 9000000),
    };
    setAuthData([...authData, data]);
    setContextData([...authData, data]);
  };
  return (
    <div>
      <SecondaryHeader currentPage={"member-detail"} />
      {!isAdd ? (
        <div className="auth-container">
          <div className="authhistory-container">
            <div className="authhistory-title">
              Authorization History
              {accessType === "admin" && (
                <span className="add-text" onClick={() => setIsAdd(true)}>
                  +Add Authorization
                </span>
              )}
            </div>
            {authData.map((item) => (
              <div className="history-item" key={item.authId}>
                <a
                  className="history-id"
                  href={`/member/${memberId}/auth/${item.authId}`}
                  onClick={(e) => onNavigate(e, item.authId)}
                >
                  {item.authId}
                </a>
                <p className="history-date">
                  authorized on{" "}
                  {moment(new Date(item.createdDate)).format("MM/DD/YYYY")}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <AddAuth
          onSubmit={onSubmit}
          onCancel={() => setIsAdd(false)}
          accessType={accessType}
        />
      )}
    </div>
  );
};

export default Authorization;
