import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SecondaryHeader } from "../../components";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import EditAuth from "./editauth";
import { AppContext } from "../../AppContext/AppContext";

const AuthDetail = () => {
  const location = useLocation();
  const params = useParams();
  const locationStateInfo = JSON.parse(location.state);
  const accessType = locationStateInfo?.accessType;
  const authId = params.authId;
  const memberId = params.id;
  const { userData } = useContext(AppContext);
  const [authData, setAuthData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [allProviders, setAllProviders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.members?.length) {
      let currentMember = userData.members.find(
        (item) => item.memberId === memberId
      );
      const currentIndex = currentMember?.authHistory.findIndex(
        (item) => item.authId === authId
      );
      if (currentIndex > -1) {
        setAuthData(currentMember?.authHistory[currentIndex]);
      }
    }
    if (userData?.providers?.length) {
      setAllProviders(userData.providers);
    }
  }, [userData, memberId, authId]);
  const onEditClick = () => {
    setIsEdit(true);
  };

  const onSubmit = (data) => {
    setIsEdit(false);
    setAuthData(data);
  };
  const getProvider = () => {
    const provider = allProviders.find(
      (item) => item.providerId === authData?.providerId
    );
    return provider;
  };
  const onProviderViewClick = (e) => {
    e.preventDefault();
    let data = { provider: getProvider(), accessType: accessType };
    navigate(`/provider/${getProvider().providerId}/detail`, {
      state: JSON.stringify(data),
    });
  };
  return (
    <>
      <SecondaryHeader currentPage={"member-detail"} />
      {!isEdit ? (
        <div className="authdetail-container">
          <div className="authdetail-title">Authorization Details</div>
          <div className="auth-info">
            <div className="auth-info-text">
              <b>Authorization Id :</b>
              <span>{authData?.authId}</span>
            </div>
            <div className="auth-info-text">
              <b>Status :</b>
              <span>{authData?.status}</span>
            </div>
            <div className="auth-info-text">
              <b>Admission Date :</b>
              <span>
                {moment(new Date(authData?.admissionDate))
                  .format("MM/DD/YYYY")
                  .toString()}
              </span>
            </div>
            <div className="auth-info-text">
              <b>Discharge Date :</b>
              <span>
                {moment(new Date(authData?.dischargeDate))
                  .format("MM/DD/YYYY")
                  .toString()}
              </span>
            </div>
            <div className="auth-info-text">
              <b>Length of Stay :</b>
              <span>
                {moment(new Date(authData?.dischargeDate))
                  .diff(new Date(authData?.admissionDate), "days")
                  .toString()}
              </span>
            </div>
            <div className="auth-info-text">
              <b>Diagnosis Code :</b>
              <span>{authData?.diagnosisCode}</span>
            </div>
            <div className="auth-info-text">
              <b>Diagnosis :</b>
              <span>{authData?.diagnosis || "-"}</span>
            </div>
            <div className="auth-info-text">
              <b>Provider :</b>
              <span>
                <a
                  href={`/provider/${authData?.providerId}/detail`}
                  onClick={onProviderViewClick}
                >
                  {getProvider()?.name}
                </a>
              </span>
            </div>
          </div>
          {accessType === "admin" && authData?.status !== "closed" && (
            <div className="auth-edit-text" onClick={onEditClick}>
              Edit
            </div>
          )}
        </div>
      ) : (
        <EditAuth
          authData={authData}
          onSubmit={onSubmit}
          onCancel={() => setIsEdit(false)}
          accessType={accessType}
        />
      )}
    </>
  );
};
export default AuthDetail;
