import MemberForm from "./memberform";

const EditMember = ({ selectedMember, onSubmit, onCancel }) => {
  return (
    <MemberForm
      type="edit"
      selectedMember={selectedMember}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};
export default EditMember;
