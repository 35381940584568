export const members = [
  {
    memberId: "MEM578585",
    firstName: "John",
    lastName: "Doe",
    dob: "12-05-1990",
    address: `Cecilia Chapman,
      711-2880, Nulla St.
      Mankato, Mississippi-96522`,
    contact: "2575637401",
    allergies: "Sinusitis",
    diagnosis: "",
    benificiary: [{ name: "John Doe", dob: "12-05-1970", relation: "Father" }],
    authHistory: [
      {
        authId: "IP2189523",
        createdDate: "03/28/2021",
        admissionDate: "03/28/2021",
        dischargeDate: "03/31/2021",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "open",
      },
      {
        authId: "IP2189522",
        createdDate: "04/28/2020",
        admissionDate: "04/28/2020",
        dischargeDate: "04/29/2020",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "inProgress",
      },
      {
        authId: "IP2189521",
        createdDate: "04/28/2019",
        admissionDate: "04/28/2019",
        dischargeDate: "04/30/2019",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
      {
        authId: "OP2189520",
        createdDate: "04/28/2018",
        admissionDate: "04/28/2018",
        dischargeDate: "04/28/2018",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
    ],
  },
  {
    memberId: "MEM847854",
    firstName: "Shwetha",
    lastName: "K",
    dob: "12-05-2000",
    address: `Cecilia Chapman,
          711-2880, Nulla St.
          Mankato, Mississippi-96522`,
    contact: "2575637401",
    allergies: "Asthma",
    diagnosis: "",
    benificiary: [{ name: "Kumar", dob: "12-28-1970", relation: "Father" }],
    authHistory: [
      {
        authId: "IP2189523",
        createdDate: "03/28/2021",
        admissionDate: "03/28/2021",
        dischargeDate: "03/31/2021",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "open",
      },
      {
        authId: "IP2189522",
        createdDate: "04/28/2020",
        admissionDate: "04/28/2020",
        dischargeDate: "04/29/2020",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "inProgress",
      },
      {
        authId: "IP2189521",
        createdDate: "04/28/2019",
        admissionDate: "04/28/2019",
        dischargeDate: "04/30/2019",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
      {
        authId: "OP2189520",
        createdDate: "04/28/2018",
        admissionDate: "04/28/2018",
        dischargeDate: "04/28/2018",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
    ],
  },
  {
    memberId: "MEM847853",
    firstName: "Krishna",
    lastName: "Kumar",
    dob: "12-05-2010",
    address: `Cecilia Chapman,
          711-2880, Nulla St.
          Mankato, Mississippi-96522`,
    contact: "2575637401",
    allergies: "Asthma",
    diagnosis: "",
    benificiary: [{ name: "Shwetha", dob: "05-28-1975", relation: "Mother" }],
    authHistory: [
      {
        authId: "IP2189523",
        createdDate: "03/28/2021",
        admissionDate: "03/28/2021",
        dischargeDate: "03/31/2021",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "open",
      },
      {
        authId: "IP2189522",
        createdDate: "04/28/2020",
        admissionDate: "04/28/2020",
        dischargeDate: "04/29/2020",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "inProgress",
      },
      {
        authId: "IP2189521",
        createdDate: "04/28/2019",
        admissionDate: "04/28/2019",
        dischargeDate: "04/30/2019",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
      {
        authId: "OP2189520",
        createdDate: "04/28/2018",
        admissionDate: "04/28/2018",
        dischargeDate: "04/28/2018",
        diagnosisCode: "NX1",
        diagnosis: "",
        providerId: "P648952475",
        status: "closed",
      },
    ],
  },
];
