import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Header, Loader } from "../components";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import ApiService from "../service/ApiService";

const AppRoute = ({ routes }) => {
  const isLoggedIn = sessionStorage.getItem("token");
  const location = useLocation();
  const { userData, setUserData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoggedIn && !userData && isLoading) {
      const fetchData = async () => {
        const members = await ApiService.getMembers();
        const providers = await ApiService.getProviders();
        setUserData({
          members: members.data,
          providers: providers.data,
        });
        setIsLoading(false);
      };
      fetchData();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [userData]);

  return (
    <Routes>
      <>
        {routes.map((route, key) =>
          route.isProtected ? (
            <Route
              key={key}
              path={route.path}
              element={
                isLoggedIn ? (
                  <>
                    <Header />
                    {isLoading ? (
                      <div className="app-loader-outer">
                        <Loader size={40} />
                      </div>
                    ) : (
                      <route.component />
                    )}
                  </>
                ) : (
                  <Navigate to="/login" state={{ from: location }} />
                )
              }
            />
          ) : (
            <Route key={key} path={route.path} element={<route.component />} />
          )
        )}
      </>
    </Routes>
  );
};
export default AppRoute;
